import * as React from "react"
// import wbmf from '../../../welcome-back-my-friend/src/main'
import Loadable from "@loadable/component"


const pageStyles = {
  color: "#232129",
  padding: 96,
  paddingBottom: 0,
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
}
const headingStyles = {
  marginTop: 0,
  marginBottom: 64,
}
const headingAccentStyles = {
  color: "#3803ce",
}
const paragraphStyles = {
  marginBottom: 48,
}

const listStyles = {
  marginBottom: 96,
  paddingLeft: 0,
}
const listItemStyles = {
  fontWeight: 300,
  fontSize: 24,
  maxWidth: 560,
  marginBottom: 30,
}

const linkStyle = {
  color: "#8954A8",
  fontWeight: "bold",
  fontSize: 16,
  verticalAlign: "5%",
}

const descriptionStyle = {
  color: "#232129",
  fontSize: 14,
  marginTop: 10,
  marginBottom: 0,
  lineHeight: 1.25,
}

const badgeStyle = {
  color: "#fff",
  backgroundColor: "#088413",
  border: "1px solid #088413",
  fontSize: 11,
  fontWeight: "bold",
  letterSpacing: 1,
  borderRadius: 4,
  padding: "4px 6px",
  display: "inline-block",
  position: "relative",
  top: -2,
  marginLeft: 10,
  lineHeight: 1,
}

const links = [
  {
    text: "FireVegas",
    url: "/casinos/fire-vegas/casino",
  },
  {
    text: "Wazamba",
    url: "/casinos/wazamba/casino",
  },
  {
    text: "Casombie",
    url: "/casinos/casombie/casino",
  },
  {
    text: "Pulsz Partner",
    url: "https://www.casino.org/casinos/pulsz/casino",
    description:
      "",
    color: "#E95800",
  },
  {
    text: "Google.com",
    url: "https://www.google.com?test=/casinos/",
    description:
      "",
    color: "#E95800",
  },
  {
    text: "Gambino Slots Partner",
    url: "https://www.casino.org/casinos/gambino-slots/casino",
    description: "",
    color: "#1099A8",
  },
  {
    text: "Fortune Coins Partner",
    url: "https://www.casino.org/casinos/fortunecoins/casino",
    description: "",
    color: "#BC027F",
  },
  {
    text: "Other link",
    url: "https://www.casino.org/",
    description: "Check if normal link is triggering the widget.",
    color: "#0D96F2",
  },
]


const IndexPage = () => {
  const ConfigComponent = Loadable(() => import ('../components/ConfigComponent'))

  return (
    <main style={pageStyles}>
      <ConfigComponent />
      <h1 style={headingStyles}>
        Welcome Back My Friend
        <span style={headingAccentStyles}>— demo staging</span>
      </h1>
      <p style={paragraphStyles}>
        Click on one of the links to test widget functionality 😎
      </p>
      <ul style={listStyles}>
        {links.map(link => (
          <li key={link.url} style={{ ...listItemStyles, color: link.color }}>
            <span>
              <a
                style={linkStyle}
                href={link.url}
                target="_blank"
                rel="noreferrer"
              >
                {link.text}
              </a>
              {link.badge && (
                <span style={badgeStyle} aria-label="New Badge">
                  NEW!
                </span>
              )}
              <p style={descriptionStyle}>{link.description}</p>
            </span>
          </li>
        ))}
      </ul>
    </main>
  )
}

export default IndexPage

export const Head = () => <title>Home Page</title>
